import React from "react";

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
  }

  render() {
    return (
      <div>
        <audio ref={this.audioRef} controls>
          <source src={this.props.audioSrc} type="audio/wav" />
        </audio>
      </div>
    );
  }
}

export default AudioPlayer;
